import { type VariantProps, cva } from 'class-variance-authority';
import { type ComponentProps, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { createPolymorphicComponent } from '../utils/create-polymorphic-component';

const avatar = cva(
  [
    'bg-gray-300',
    'overflow-hidden',
    'inline-flex',
    'justify-center',
    'items-center',
  ],
  {
    variants: {
      size: {
        sm: ['w-8', 'h-8', 'min-w-8', 'min-h-8', 'text-sm'],
        md: ['w-9', 'h-9', 'min-w-9', 'min-h-9'],
        lg: ['w-10', 'h-10', 'min-w-10', 'min-h-10'],
        xl: ['w-14', 'h-14', 'min-w-14', 'min-h-14'],
      },
      radius: {
        none: ['rounded-none'],
        sm: ['rounded-sm'],
        md: ['rounded-md'],
        lg: ['rounded-lg'],
        full: ['rounded-full'],
      },
    },
    defaultVariants: {
      size: 'md',
      radius: 'full',
    },
  },
);

export type AvatarProps = VariantProps<typeof avatar> &
  ComponentProps<'div'> & {
    component?: any;
    src?: string | null;
    alt?: string;
  };

export function _Avatar({
  component,
  src,
  alt,
  className,
  size,
  radius,
  children,
  ...props
}: AvatarProps) {
  const C = component || 'div';
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, []);

  return (
    <C className={twMerge(avatar({ size, radius }), className)} {...props}>
      {src && !error ? (
        <img
          src={src}
          alt={alt}
          loading='lazy'
          className='w-full h-full object-cover'
          onError={() => {
            setError(true);
          }}
        />
      ) : (
        children
      )}
    </C>
  );
}

export const Avatar = createPolymorphicComponent<'div', AvatarProps>(_Avatar);
