import type { GetCollectionOptions } from '../collection';
import {
  type SWROptions,
  type UseCollectionOptions,
  useCollection,
} from '../use-collection';
import type {
  GetPerformancesParams,
  GetPerformancesScheduleParams,
  SearchPerformancesParams,
} from './service';
import type { Performance } from './types';

export function usePerformances(
  options: UseCollectionOptions<
    Performance,
    Omit<GetPerformancesParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Performance>(
    'api/performances',
    options,
    requestOptions,
    swrConfig,
  );
}

export function usePerformancesSchedule(
  options: UseCollectionOptions<
    Performance,
    Omit<GetPerformancesScheduleParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Performance>(
    'api/performances/schedule',
    options,
    requestOptions,
    swrConfig,
  );
}

export function usePerformancesSearch(
  options: UseCollectionOptions<
    Performance,
    Omit<SearchPerformancesParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Performance>(
    'api/performances/search',
    options,
    requestOptions,
    swrConfig,
  );
}
