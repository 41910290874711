import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { usePathname } from '../i18n/routing';

export function useRouterHash() {
  return typeof window === 'object'
    ? (location.hash.split('#')[1] ?? null)
    : null;
}

export function useRouteChange(callback: (url: string) => void) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // biome-ignore lint/correctness/useExhaustiveDependencies: call function when url changes
  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    callback(url);
  }, [pathname, searchParams]);
}
