import * as RadixDialog from '@radix-ui/react-dialog';
import { Cross1Icon } from '@radix-ui/react-icons';
import { type VariantProps, cva, cx } from 'class-variance-authority';
import { createContext, use } from 'react';

const dialog = cva(
  [
    'fixed overflow-hidden bg-white rounded-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 pt-11 pb-4 data-[state="open"]:animate-fade-in data-[state="closed"]:animate-fade-out',
  ],
  {
    variants: {
      size: {
        xs: ['w-80'],
        sm: ['w-96'],
        md: ['w-[440px]'],
        lg: ['w-[620px]'],
        xl: ['w-[780px]'],
        '2xl': ['w-[960px]'],
      },
      fullScreen: {
        true: [
          'w-screen',
          'h-screen',
          'rounded-none',
          'max-w-full',
          'max-h-full',
        ],
      },
    },
    defaultVariants: {
      size: 'md',
      fullScreen: false,
    },
  },
);

const dialogContentInner = cva(['overflow-y-auto p-4'], {
  variants: {
    fullScreen: {
      true: ['max-h-full'],
      false: ['max-h-[90dvh]'],
    },
  },
});

export type DialogProps = Omit<RadixDialog.DialogProps, 'modal'> &
  VariantProps<typeof dialog>;
const DialogContext = createContext<Pick<DialogProps, 'size' | 'fullScreen'>>(
  {},
);
export function Dialog({ size, fullScreen, ...props }: DialogProps) {
  return (
    <DialogContext value={{ size, fullScreen }}>
      <RadixDialog.Root {...props} />
    </DialogContext>
  );
}

export type DialogTriggerProps = RadixDialog.DialogTriggerProps;
export const DialogTrigger = RadixDialog.Trigger;

export type DialogContentProps = RadixDialog.DialogContentProps;
export function DialogContent({ children, ...props }: DialogContentProps) {
  const { size, fullScreen } = use(DialogContext);
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className='fixed inset-0 bg-black/70 z-50 data-[state="open"]:animate-fade-in data-[state="closed"]:animate-fade-out' />
      <RadixDialog.Content {...props} className={dialog({ size, fullScreen })}>
        <div className={dialogContentInner({ fullScreen })}>{children}</div>
        <RadixDialog.Close className='absolute top-2 right-2 w-8 h-8 rounded-md flex justify-center items-center'>
          <Cross1Icon />
        </RadixDialog.Close>
      </RadixDialog.Content>
    </RadixDialog.Portal>
  );
}

export type DialogTitleProps = RadixDialog.DialogTitleProps;
export function DialogTitle({ className, ...props }: DialogTitleProps) {
  return (
    <RadixDialog.Title
      className={cx('text-lg font-medium text-center', className)}
      {...props}
    />
  );
}
