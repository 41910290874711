import { Button, type ButtonProps } from '@afishauz/ui-kit/button';
import { Spinner } from '@afishauz/ui-kit/spinner';
import { useTranslations } from 'next-intl';

export function LoadingButton({
  loading,
  ...rest
}: ButtonProps & { loading?: boolean }) {
  const t = useTranslations('common');
  return (
    <Button {...rest} fullWidth>
      {t('elements.loading_button.text')}
      {loading && (
        <>
          &nbsp;
          <Spinner size={16} />
        </>
      )}
    </Button>
  );
}
