import { type VariantProps, cva } from 'class-variance-authority';
import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { createPolymorphicComponent } from '../utils/create-polymorphic-component';

const button = cva(
  [
    'inline-block',
    'text-center',
    'text-nowrap',
    'transition-colors',
    'disabled:bg-gray-50',
    'disabled:border-none',
    'disabled:text-gray-400',
    'disabled:cursor-not-allowed',
  ],
  {
    variants: {
      color: {
        default: ['[&:not(:disabled)]:active:bg-gray-400'],
        primary: ['[&:not(:disabled)]:active:bg-afisha-700'],
        secondary: ['[&:not(:disabled)]:active:bg-blue-700'],
      },
      variant: {
        contained: [],
        outlined: ['bg-white', 'border'],
      },
      size: {
        sm: ['px-4', 'h-9', 'rounded-md', 'text-sm', 'leading-9'],
        md: ['px-6', 'h-10', 'rounded-lg', 'text-base', 'leading-10'],
        lg: ['px-8', 'h-[52px]', 'rounded-xl', 'text-base', 'leading-[52px]'],
      },
      fullWidth: {
        true: ['w-full'],
      },
    },
    compoundVariants: [
      {
        color: 'default',
        variant: 'contained',
        class: [
          'bg-gray-100',
          'text-black',
          'md:[&:not(:disabled)]:hover:bg-gray-200',
        ],
      },
      {
        color: 'default',
        variant: 'outlined',
        class: ['border-gray-500', 'md:[&:not(:disabled)]:hover:bg-gray-200'],
      },
      {
        color: 'primary',
        variant: 'contained',
        class: [
          'bg-afisha',
          'text-white',
          'md:[&:not(:disabled)]:hover:bg-afisha-600',
        ],
      },
      {
        color: 'primary',
        variant: 'outlined',
        class: [
          'border-afisha',
          'text-afisha',
          'md:[&:not(:disabled)]:hover:bg-afisha',
          'md:[&:not(:disabled)]:hover:text-white',
        ],
      },
      {
        color: 'secondary',
        variant: 'contained',
        class: [
          'bg-blue-500',
          'text-white',
          'md:[&:not(:disabled)]:hover:bg-blue-600',
        ],
      },
      {
        color: 'secondary',
        variant: 'outlined',
        class: [
          'border-blue-500',
          'text-blue-500',
          'md:[&:not(:disabled)]:hover:bg-blue-500',
          'md:[&:not(:disabled)]:hover:text-white',
        ],
      },
    ],
    defaultVariants: {
      color: 'default',
      variant: 'contained',
      size: 'md',
      fullWidth: false,
    },
  },
);

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'color'
> &
  VariantProps<typeof button> & {
    component?: any;
    leftSection?: ReactNode;
    rightSection?: ReactNode;
  };

function _Button({
  component,
  className,
  color,
  variant,
  size,
  fullWidth,
  leftSection,
  rightSection,
  children,
  ...rest
}: ButtonProps) {
  const C = component || 'button';
  return (
    <C
      {...rest}
      className={twMerge(
        button({ color, variant, size, fullWidth, className }),
      )}
    >
      {leftSection || rightSection ? (
        <span className='flex justify-center items-center gap-x-2 text-nowrap'>
          {leftSection}
          {children}
          {rightSection}
        </span>
      ) : (
        <>{children}</>
      )}
    </C>
  );
}

export const Button = createPolymorphicComponent<'button', ButtonProps>(
  _Button,
);
