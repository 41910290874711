import { API_ORIGIN, DEFAULT_PREVIEW } from '@afishauz/api/config';
import type { Metadata } from 'next';
import { getTranslations } from 'next-intl/server';
import type { PageProps } from '../pages/config';

export function createGenerateMetadata<
  P extends Record<string, string | string[]> = Record<string, never>,
  S extends Record<string, string | string[]> = Record<string, never>,
>(
  generateMetadataWithTranslation: (
    props: PageProps<P, S> & {
      t: Awaited<ReturnType<typeof getTranslations>>;
    },
  ) => Promise<Metadata> | Metadata,
  {
    namespace,
    pathname,
  }: { pathname: `/${string}` | '__DYNAMIC__'; namespace?: string },
): (props: PageProps<P, S>) => Promise<Metadata> {
  return async (props: PageProps<P, S>) => {
    const { locale } = await props.params;
    const t = await getTranslations({
      locale,
      namespace: namespace as any,
    });
    const metadata = await generateMetadataWithTranslation({ ...props, t });
    const normalizePathname = (pathname: string) =>
      pathname === '/' ? '' : pathname;
    return {
      metadataBase: new URL(API_ORIGIN),
      openGraph: {
        images: [new URL(DEFAULT_PREVIEW, API_ORIGIN).toString()],
        ...metadata.openGraph,
      },
      alternates: {
        canonical:
          pathname === '__DYNAMIC__'
            ? undefined
            : `/${locale}${normalizePathname(pathname)}`,
        languages:
          pathname === '__DYNAMIC__'
            ? undefined
            : {
                ru: `/ru${normalizePathname(pathname)}`,
                'uz-Latn': `/uz${normalizePathname(pathname)}`,
              },
        ...metadata.alternates,
      },
      ...metadata,
    };
  };
}
