import type { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type TextareaProps = ComponentProps<'textarea'>;

export function Textarea({ className, ...rest }: TextareaProps) {
  return (
    <div className={twMerge('relative block', className)}>
      <textarea
        {...rest}
        className='border border-gray-300 focus:border-afisha disabled:bg-gray-50 disabled:cursor-default rounded outline-none resize-none px-3 py-2.5 w-full bg-transparent'
      />
    </div>
  );
}
