import { updateUser as updateProfile } from '@afishauz/api/users';
import { useLocale } from '@afishauz/core/utils/i18n';
import { useUser } from '@afishauz/core/utils/user';
import { logger } from '@afishauz/lib/logger';
import { Avatar } from '@afishauz/ui-kit/avatar';
import { Button } from '@afishauz/ui-kit/button';
import { Spinner } from '@afishauz/ui-kit/spinner';
import { TextInput } from '@afishauz/ui-kit/text-input';
import { Textarea } from '@afishauz/ui-kit/textarea';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons/faPenToSquare';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { useTranslations } from 'next-intl';
import {
  type ChangeEvent,
  type FormEvent,
  useRef,
  useState,
  useTransition,
} from 'react';
import toast from 'react-hot-toast';

export function UserSettings() {
  const t = useTranslations('common');
  const locale = useLocale();
  const { user, updateUser } = useUser();
  const formRef = useRef<HTMLFormElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [avatarUrl, setAvatarUrl] = useState(user?.avatarUrl);
  const [isPending, startTransition] = useTransition();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    startTransition(async () => {
      try {
        const fullName = formRef.current?.fullName.value;
        const email = formRef.current?.email.value;
        const biography = formRef.current?.bio.value;
        const telegramUsername = formRef.current?.telegramUsername.value;
        const file = avatarInputRef.current?.files?.[0];
        const base64Avatar = file
          ? (await imageToBase64String(file))?.toString()
          : undefined;
        const avatarFile = base64Avatar?.slice(base64Avatar.indexOf(',') + 1);
        const updates = {
          email,
          telegramUsername,
          avatarFile,
          avatarRemove: !file,
          translations: {
            [locale]: {
              fullName,
              biography,
              locale,
            },
          },
        };
        if (user) {
          const id = /(\d+)$/.exec(user['@id'])?.[1];
          if (id) {
            const data = await updateProfile(Number(id), updates);
            if (data) {
              updateUser(data);
            }
            toast.success(t('layouts.user_settings.update_success'));
          }
        }
      } catch (error) {
        logger.error(error);
        toast.error(t('layouts.user_settings.update_error'));
      }
    });
  };

  const handleAvatarChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const handleAvatarRemove = () => {
    setAvatarUrl(null);
    if (avatarInputRef.current) {
      avatarInputRef.current.value = '';
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Avatar
          src={user.avatarUrl}
          alt={user.fullName}
          size='lg'
          component={'button' as any}
        />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          align='end'
          sideOffset={-40}
          className='w-72 bg-white rounded-md z-50 overflow-hidden shadow-afisha-md py-2 data-[state="open"]:animate-fade-in data-[state="closed"]:animate-fade-out'
        >
          <form
            className='w-full max-w-full flex flex-col px-4 py-2 gap-y-2 max-h-[500px] overflow-y-auto'
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className='flex items-end gap-x-3'>
              <div className='relative flex'>
                <Avatar src={avatarUrl} alt={user?.fullName} size='xl' />
                {avatarUrl && (
                  <button
                    type='button'
                    className='absolute -top-1 -right-1 w-6 h-6 rounded-full flex justify-center items-center bg-red-600 text-white'
                    onClick={handleAvatarRemove}
                    aria-label='Remove avatar'
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
              <Button
                size='sm'
                leftSection={<FontAwesomeIcon icon={faPenToSquare} />}
                component={'label' as any}
                htmlFor='avatar'
              >
                {t('layouts.user_settings.change_avatar')}
              </Button>
              <input
                ref={avatarInputRef}
                id='avatar'
                type='file'
                accept='image/jpeg, image/jpg, image/png, image/gif'
                className='sr-only'
                onChange={handleAvatarChange}
              />
            </div>
            <div>
              <label htmlFor='fullName' className='text-xs text-gray-500'>
                {t('layouts.user_settings.full_name')}
              </label>
              <TextInput
                type='text'
                size='sm'
                id='fullName'
                name='fullName'
                defaultValue={user?.fullName}
                required
              />
            </div>
            <div>
              <label htmlFor='email' className='text-xs text-gray-500'>
                {t('layouts.user_settings.email')}
              </label>
              <TextInput
                type='email'
                size='sm'
                id='email'
                name='email'
                defaultValue={user?.email}
              />
            </div>
            <div>
              <label htmlFor='bio' className='text-xs text-gray-500'>
                {t('layouts.user_settings.bio')}
              </label>
              <Textarea
                id='bio'
                name='bio'
                defaultValue={user?.biography ?? ''}
              />
            </div>
            <div>
              <label
                htmlFor='telegram-username'
                className='text-xs text-gray-500'
              >
                {t('layouts.user_settings.telegram_username')}
              </label>
              <TextInput
                type='text'
                size='sm'
                id='telegram-username'
                name='telegramUsername'
                defaultValue={user?.telegramUsername ?? ''}
              />
            </div>
            <Button size='sm' color='primary'>
              {t('layouts.user_settings.save')}
              {isPending && (
                <>
                  &nbsp;
                  <Spinner size={14} />
                </>
              )}
            </Button>
            <Button
              type='button'
              size='sm'
              component={'a' as any}
              href='/logout'
            >
              {t('layouts.user_settings.log_out')}
            </Button>
          </form>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}

async function imageToBase64String(
  file: File,
): Promise<string | ArrayBuffer | null> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
}
