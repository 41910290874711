'use client';

import { type User, getMe } from '@afishauz/api/users';
import { type ReactNode, createContext, use, useEffect, useState } from 'react';
import { useLocale } from './i18n';

export interface AppContextInterface {
  user: User | null;
  updateUser: (user: User) => void;
}

const UserContext = createContext<AppContextInterface>({
  user: null,
  updateUser: () => {},
});

export function UserProvider({ children }: { children?: ReactNode }) {
  const locale = useLocale();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await getMe(
          { locale },
          { credentials: 'include', cache: 'no-store' },
        );
        if (data) {
          setUser(data);
        }
      } catch {
        setUser(null);
      }
    };
    fetchUser();
  }, [locale]);

  return (
    <UserContext
      value={{
        user,
        updateUser: setUser,
      }}
    >
      {children}
    </UserContext>
  );
}

export function useUser() {
  return use(UserContext);
}
