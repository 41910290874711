'use client';

import { parseCookies, setCookie } from '@afishauz/lib/cookies';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import { useCallback, useEffect, useState } from 'react';
import { AD_ZONES } from '../config';
import { usePathname } from '../i18n/routing';
import { useAdvertisement } from '../utils/ads';
import { useLocale } from '../utils/i18n';

const ONE_HOUR_S = 60 * 60;
const FOUR_SECONDS_MS = 4 * 1000;
const ONE_MINUTE_MS = 60 * 1000;
const COOKIE_NAME = 'floatingAdButtonHidden';

export function FloatingAdBanner() {
  const pathname = usePathname();
  const locale = useLocale();
  const section = pathname.split('/')[2] || 'mainpage';
  const { data, ref } = useAdvertisement({
    zoneId: AD_ZONES[locale].FIXED_BUTTON,
    source: section,
  });
  const [open, setOpen] = useState(false);

  const close = useCallback(() => {
    setOpen(false);
    setCookie(COOKIE_NAME, 'true', {
      path: '/',
      maxAge: ONE_HOUR_S,
    });
  }, []);

  useEffect(() => {
    const cookies = parseCookies();
    if (cookies[COOKIE_NAME] !== 'true' && data) {
      const timeoutId = setTimeout(() => {
        setOpen(true);
      }, FOUR_SECONDS_MS);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        close();
      }, ONE_MINUTE_MS);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [open, close]);

  if (!data) {
    return null;
  }

  const { clickUrl, creativeUrl, width, height } = data;

  return (
    <Dialog.Root modal={false} open={open}>
      <Dialog.Portal>
        <Dialog.Content
          ref={ref}
          className='fixed bottom-0 left-1/2 -translate-x-1/2 data-[state="open"]:animate-fade-in data-[state="closed"]:animate-fade-out z-10'
        >
          <a
            href={clickUrl}
            target='_blank'
            rel='noreferrer'
            aria-label='floating ad'
          >
            <img src={creativeUrl} alt='' width={width} height={height} />
          </a>
          <Dialog.Close
            className='absolute flex top-0 -right-7 w-6 h-6 justify-center items-center rounded-full bg-gray-200 text-white'
            onClick={close}
            aria-label='close banner'
          >
            <FontAwesomeIcon icon={faTimes} />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
