'use client';
import type { Locale } from '@afishauz/api/config';
import {
  checkPushSubscriberExist,
  createPushSubscriber,
  deactivatePushSubscriber,
} from '@afishauz/api/push-subscribers';
import { initializeApp } from 'firebase/app';
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import md5 from 'md5';
import {
  type ReactNode,
  createContext,
  use,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocale } from './i18n';

const firebaseConfig = {
  apiKey: 'AIzaSyDPTFn5Fif27wFVlmg_BXbmL0mEsPDcxWk',
  authDomain: 'afishauz-global.firebaseapp.com',
  databaseURL: 'https://afishauz-global.firebaseio.com',
  projectId: 'afishauz-global',
  storageBucket: 'afishauz-global.appspot.com',
  messagingSenderId: '998521132100',
  appId: '1:998521132100:web:a719be63c945bb56799811',
  measurementId: 'G-B3ZZB9Z6MW',
};

const vapidKey =
  'BIqPBQVK2hoyiZxoyPNLDskmhMt6URLHOZ4c-pie2wJSzpnvwcWCFO41XguigNTUIko7qxnH4VKzN78-bG-CLZI';

const app = initializeApp(firebaseConfig);

export function isNotificationsApiSupported() {
  return (
    typeof window !== 'undefined' &&
    typeof Notification === 'function' &&
    Notification.permission !== 'denied'
  );
}

export async function subscribe(locale: Locale) {
  try {
    const success = await isSupported();
    if (!success) {
      return false;
    }
    const messaging = getMessaging(app);
    const token = await getToken(messaging, {
      vapidKey,
    });
    await createPushSubscriber({
      platform: 'WEB_BROWSER',
      token,
      locale,
    });
    return true;
  } catch {
    return false;
  }
}

export async function unsubscribe() {
  try {
    const success = await isSupported();
    if (!success) {
      return false;
    }
    const messaging = getMessaging(app);
    const token = await getToken(messaging, { vapidKey });
    await deleteToken(messaging);
    await deactivatePushSubscriber({
      platform: 'WEB_BROWSER',
      token,
    });
    return true;
  } catch {
    return false;
  }
}

type SubscriptionStatus = 'subscribed' | 'unsubscribed' | 'unsupported' | null;

const PushNotificationsContext = createContext<{
  status: SubscriptionStatus;
  updateStatus: () => Promise<void>;
  token: string | null;
}>({ status: null, updateStatus: Promise.resolve, token: null });

export function PushNotificationsProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [status, setStatus] = useState<SubscriptionStatus>(null);
  const [token, setToken] = useState<string | null>(null);
  const locale = useLocale();

  const updateStatus = useCallback(async () => {
    if (!isNotificationsApiSupported()) {
      setStatus('unsupported');
      return;
    }
    if (Notification.permission === 'default') {
      setStatus('unsubscribed');
      return;
    }
    const messaging = getMessaging(app);
    try {
      const token = await getToken(messaging, { vapidKey });
      setToken(token);
      const exists = await checkPushSubscriberExist({
        platform: 'WEB_BROWSER',
        tokenHash: md5(token),
        locale,
      });
      setStatus(exists ? 'subscribed' : 'unsubscribed');
    } catch {}
  }, [locale]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  useEffect(() => {
    if (!isNotificationsApiSupported()) {
      return;
    }
    isSupported().then(success => {
      if (!success) {
        return;
      }
      const messaging = getMessaging(app);
      return onMessage(messaging, payload => {
        const { title, body, icon } = payload.notification ?? {};
        const { link } = payload.fcmOptions ?? {};
        if (!title) {
          return;
        }
        const notification = new Notification(title, {
          icon,
          body,
          badge: icon,
        });
        notification.addEventListener('click', () => {
          if (location.href === link) {
            window.focus();
          } else {
            window.open(link, '_blank');
          }
          notification.close();
        });
      });
    });
  });

  return (
    <PushNotificationsContext value={{ status, updateStatus, token }}>
      {children}
    </PushNotificationsContext>
  );
}

export function usePushNotifications() {
  return use(PushNotificationsContext);
}
