import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { CheckIcon } from '@radix-ui/react-icons';
import { cx } from 'class-variance-authority';

export type DropdownMenuProps = RadixDropdownMenu.DropdownMenuProps;
export const DropdownMenu = RadixDropdownMenu.Root;

export type DropdownMenuTriggerProps =
  RadixDropdownMenu.DropdownMenuTriggerProps;
export const DropdownMenuTrigger = RadixDropdownMenu.Trigger;

export type DropdownMenuContentProps =
  RadixDropdownMenu.DropdownMenuContentProps;

export function DropdownMenuContent({
  sideOffset = 5,
  align = 'start',
  children,
  ...props
}: DropdownMenuContentProps) {
  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content
        {...props}
        sideOffset={sideOffset}
        align={align}
        className='bg-white rounded-md shadow-afisha-md z-10 py-2'
      >
        <div className='max-h-80 overflow-y-auto px-2'>{children}</div>
        <RadixDropdownMenu.Arrow className='fill-white' />
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Portal>
  );
}

export type DropdownMenuItemProps = RadixDropdownMenu.DropdownMenuItemProps;

const dropdownMenuItemClassName =
  'px-3 py-2 outline-none data-[highlighted]:bg-gray-100 rounded-md leading-none cursor-default';

export function DropdownMenuItem(props: DropdownMenuItemProps) {
  return (
    <RadixDropdownMenu.Item {...props} className={dropdownMenuItemClassName} />
  );
}

export type DropdownMenuCheckboxItemProps =
  RadixDropdownMenu.DropdownMenuCheckboxItemProps;

export function DropdownMenuCheckboxItem({
  children,
  ...props
}: DropdownMenuCheckboxItemProps) {
  return (
    <RadixDropdownMenu.CheckboxItem
      {...props}
      className={cx(dropdownMenuItemClassName, 'flex items-center gap-x-2')}
    >
      {children}
      <RadixDropdownMenu.ItemIndicator>
        <CheckIcon />
      </RadixDropdownMenu.ItemIndicator>
    </RadixDropdownMenu.CheckboxItem>
  );
}
