import {
  Arrow,
  Content,
  Portal,
  Provider,
  Root,
  type TooltipContentProps,
  Trigger,
} from '@radix-ui/react-tooltip';
import type { PropsWithChildren } from 'react';

export interface TooltipProps extends PropsWithChildren {
  title: string;
  position?: TooltipContentProps['side'];
}

export function Tooltip({ title, position, children }: TooltipProps) {
  return (
    <Provider>
      <Root>
        <Trigger asChild>{children}</Trigger>
        <Portal>
          <Content
            side={position}
            className='bg-black/70 text-white px-2 py-1 rounded text-sm'
          >
            {title}
            <Arrow className='fill-black/70' />
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
}
