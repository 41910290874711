import { ImageWithFallback } from '@afishauz/core/ui/image-with-fallback';
import { Link } from '@afishauz/core/ui/link';
import type { ListItemProps } from '@afishauz/core/ui/list-item';
import { Text } from '@afishauz/ui-kit/text';
import type { PropsWithChildren } from 'react';

export function ListItem({ link, img = {}, children }: ListItemProps) {
  return (
    <li className='w-full'>
      <Link href={link.href} nativeLink={link.native} className='block'>
        <article className='flex items-start gap-x-3 p-3 rounded-md hover:bg-gray-100'>
          <div className='min-w-[57px] w-[57px] h-[38px]'>
            <ImageWithFallback
              src={img.src}
              alt={img.alt}
              className='rounded'
            />
          </div>
          <div className='truncate'>{children}</div>
        </article>
      </Link>
    </li>
  );
}

export function ListTitle({ children }: PropsWithChildren) {
  return (
    <Text component='h2' weight={500} className='mb-2 mx-3'>
      {children}
    </Text>
  );
}
