'use client';

import { useRouter } from '@afishauz/core/i18n/routing';
import { useLocale } from '@afishauz/core/utils/i18n';
import { Language } from '@afishauz/icons';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';

export function LocaleSwitch() {
  const t = useTranslations('common');
  const locale = useLocale();
  const router = useRouter();
  const searchParams = useSearchParams();

  const handleLocaleChange = () => {
    const alternate = document.head.querySelector<HTMLLinkElement>(
      `link[hreflang="${locale === 'ru' ? 'uz-Latn' : 'ru'}"]`,
    );
    if (alternate?.href) {
      const url = new URL(alternate.href);
      url.search = searchParams.toString();
      router.push(url.toString());
    } else {
      router.push('/', { locale: locale === 'ru' ? 'uz' : 'ru' });
    }
  };

  return (
    <button
      type='button'
      className='px-3 flex items-center gap-x-3 text-left'
      onClick={handleLocaleChange}
    >
      <Language width={20} className='text-afisha' />
      <span className='flex flex-col'>
        <span className='text-sm'>
          {t(`layouts.header.locale.${locale === 'ru' ? 'uz' : 'ru'}`)}
        </span>
        <span className='text-xs mt-1 text-gray-400'>
          {t(`layouts.header.locale.${locale}`)}
        </span>
      </span>
    </button>
  );
}
