import type { GetCollectionOptions } from '../collection';
import {
  type SWROptions,
  type UseCollectionOptions,
  useCollection,
} from '../use-collection';
import type {
  GetVideosParams,
  GetVideosPremieresParams,
  GetVideosScheduleParams,
  SearchVideosParams,
} from './service';
import type { Video } from './types';

export function useVideos(
  options: UseCollectionOptions<Video, Omit<GetVideosParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Video>('api/videos', options, requestOptions, swrConfig);
}

export function useVideosSchedule(
  options: UseCollectionOptions<
    Video,
    Omit<GetVideosScheduleParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Video>(
    'api/videos/schedule',
    options,
    requestOptions,
    swrConfig,
  );
}

export function useVideosPremieres(
  options: UseCollectionOptions<
    Video,
    Omit<GetVideosPremieresParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Video>(
    'api/videos/premieres',
    options,
    requestOptions,
    swrConfig,
  );
}

export function useVideosSearch(
  options: UseCollectionOptions<Video, Omit<SearchVideosParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Video>(
    'api/videos/search',
    options,
    requestOptions,
    swrConfig,
  );
}
