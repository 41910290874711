'use client';

import { AuthModal } from '@/ui/shared/header/auth-modal';
import { MainNavigation } from '@/ui/shared/header/main-navigation';
import { Search } from '@/ui/shared/header/search';
import { TopNavigation } from '@/ui/shared/header/top-navigation';
import { UserSettings } from '@/ui/shared/header/user-settings';
import type { MenuItem } from '@afishauz/api/menu-items';
import { Link } from '@afishauz/core/ui/link';
import { useUser } from '@afishauz/core/utils/user';
import { Afishauz } from '@afishauz/icons';
import { Button } from '@afishauz/ui-kit/button';
import { Container } from '@afishauz/ui-kit/container';
import { useTranslations } from 'next-intl';
import { Suspense, useState } from 'react';

export function Header({
  menuItems,
}: {
  menuItems: MenuItem[];
}) {
  const [authModalOpened, setAuthModalOpened] = useState(false);
  const { user } = useUser();
  const t = useTranslations('common');

  return (
    <header className='mb-16'>
      <Container>
        <div className='flex items-center py-6'>
          <Link href='/' className='mr-9' aria-label='go to home page'>
            <Afishauz width={172} className='text-afisha' />
          </Link>
          <Suspense>
            <Search />
          </Suspense>
          <TopNavigation />
          <div className='ml-auto'>
            {user ? (
              <UserSettings />
            ) : (
              <>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                    setAuthModalOpened(true);
                  }}
                >
                  {t('layouts.header.log_in')}
                </Button>
                <AuthModal
                  show={authModalOpened}
                  onClose={() => {
                    setAuthModalOpened(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <MainNavigation menuItems={menuItems} />
      </Container>
    </header>
  );
}
